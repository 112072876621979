<!-- 新增居民规约 -->
<template>
  <!-- data-range-keys 开始时间和结束时间的联动 -->
  <div>
    <ax-form
      ref="formBox"
      :formBuilder="formBuilder"
      :data-range-keys="dateRangeKeys"
      @change="onFormChange"
    >
      <div slot="gridId" style="display: flex">
        <a-cascader
          :options="options"
          placeholder="请选择所属网格"
          change-on-select
          :show-search="{ filter }"
          @change="onChange"
          allowClear
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :z-index="666"
          v-decorator="[
            'options',
            { rules: [{ required: true, message: '请选择' }] },
          ]"
        />
      </div>
      <div
        slot="statuteContent"
        v-decorator="[
          'myContent',
          {
            rules: [{ required: true, message: '正文不能为空' }],
          },
        ]"
      >
        <wangeditor @onChange="quillHtml" ref="editor" :content="myContent" />
      </div>
    </ax-form>
    <div style="display: flex; justify-content: flex-end">
      <a-button type="primary" @click="onSubmit" :loading="saveType">
        保存
      </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </div>
</template>
<script>
import api from "./api";
import wangeditor from "../../../Acomponents/quillEditor/wangeditor.vue";
const formList = [
  {
    label: "标题",
    type: "",
    model: "statuteTitle",
    options: { placeholder: "请输入", showTime: true, maxLength: 20 },
    col: { span: 24 },
    rules: [
      {
        required: true,
        message: "标题不能为空",
      },
    ],
  },

  {
    label: "所属网格",
    type: "select",
    model: "gridId",
    options: { placeholder: "请选择网格" },
    col: { span: 12 },
    rules: [{ required: true, message: "所属网格不能为空" }],
  },
  {
    label: "所属社区",
    type: "",
    model: "communityName",
    options: { placeholder: "请输入", maxLength: 20 },
    col: { span: 12 },
    rules: [{ required: false, message: "所属社区不能为空" }],
  },
  {
    label: "生效时间",
    type: "datePicker",
    model: "effectiveTime",
    options: { showTime: true },
    col: { span: 12 },
    rules: [{ required: true, message: "生效时间不能为空" }],
  },
  {
    label: "失效时间",
    type: "datePicker",
    model: "expirationTime",
    options: { showTime: true },
    col: { span: 12 },
    // rules: [{ required: true, message: "失效时间不能为空" }],
  },
  {
    label: "正文",
    type: "",
    model: "statuteContent",
    options: {},
    col: { span: 24 },
    rules: [{ required: true }],
  },
];

export default {
  components: { wangeditor },
  data() {
    return {
      api,
      gridId: "",
      latitude: "",
      longitude: "",
      quillHtmls: "",
      options: [],
      saveType: false,
      effectiveTime: "",
      expirationTime: "",
      myContent: "", // 你的文本内容
      address: "",
      dateRangeKeys: [{ startKey: "effectiveTime", endKey: "expirationTime" }],
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 125 },
        { col: { span: 8 } }
      ),
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.owningGrid();
  },
  methods: {
    onFormChange(e, type) {
      // 生效时间
      if (type === "effectiveTime") {
        // 直接获取生效时间
        let effectiveTime = e.trim(); // 生效时间

        // 更新生效时间
        this.effectiveTime = effectiveTime;
      }

      // 失效时间
      if (type === "expirationTime") {
        // 将时间字符串按逗号分割成数组
        let times = e.split(",");
        let expirationTime = times[0].trim(); // 失效时间

        // 更新失效时间
        this.expirationTime = expirationTime;

        // 比较生效时间与失效时间
        if (
          this.effectiveTime && // 确保生效时间已设置
          new Date(expirationTime) <= new Date(this.effectiveTime)
        ) {
          // 清空生效时间和失效时间
          this.effectiveTime = "";
          this.expirationTime = "";
          this.$refs.formBox.setFieldsValue({
            effectiveTime: "",
            expirationTime: "",
          });
        }
      }
    },

    filter(inputValue, path) {
      return path.some(
        (option) =>
          option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
    },
    // 获取所属网格数据
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.options = options; // 更新选项数据
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 级联选择器改变的回调函数
    onChange(value, e) {
      // 获取最后一个ID
      const lastId = value[value.length - 1];
      this.gridId = lastId;
    },
    // 富文本编辑器获取内容
    quillHtml(e) {
      this.quillHtmls = e;
      this.$refs.formBox.setFieldsValue({
        myContent: this.quillHtmls,
      });
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        value.gridId = this.gridId;
        value.statuteContent = this.quillHtmls;
        try {
          const res = await api.add(value);
          // console.log(res);
          if (res.status === 200) {
            this.$message.success("新增成功");
            this.saveType = false;
            // 提交成功后关闭弹窗
            this.$emit("closeDialog");
            this.refreshTable();
          } else {
            this.$message.error("新增失败");
            this.saveType = true; // 验证通过后再设置
          }
        } catch (err) {
          console.log(error);
          this.$message.error("新增失败");
          this.saveType = false;
        }
      });
    },
    //关闭弹窗
    closeDialog() {
      this.$emit("closeDialog");
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
    
<style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.interact {
  margin-top: 2%;
  margin-bottom: 2%;
}
.button2 {
  display: flex;
  justify-content: space-between;
}
</style>
    